import { StateCreator, create } from "zustand";

type State = {
  iframeUrl: string;
  editingSessionAuthenticatorId: number;
  editMode: boolean;
  editItemId: number;
  eyeballSyncing: boolean;
  refreshReview: boolean;
};

type Actions = {
  enterEditMode: (editItemId: number) => void;
  exitEditMode: () => void;
  setEyeballSyncing: (eyeballSyncing: boolean) => void;
  setRefreshReview: (refreshReview: boolean) => void;
};

type GameReviewStore = State & Actions;

const initialState: State = {
  iframeUrl: window.MLBBest.envVariables.EYEBALL_APP_URL ?? "",
  editingSessionAuthenticatorId: -1,
  editMode: false,
  editItemId: -1,
  eyeballSyncing: false,
  refreshReview: false,
};

const createGameReviewStore: StateCreator<GameReviewStore, [], [], GameReviewStore> = (set) => ({
  ...initialState,
  enterEditMode: (editItemId: number) => set({ editMode: true, editItemId }),
  exitEditMode: () => set({ editMode: false, editItemId: -1, eyeballSyncing: false, refreshReview: false }),
  setEyeballSyncing: (eyeballSyncing: boolean) => set({ eyeballSyncing }),
  setRefreshReview: (refreshReview: boolean) => set({ refreshReview }),
});

const useGameReviewStore = create<GameReviewStore>()((...a) => ({
  ...createGameReviewStore(...a),
}));

export default useGameReviewStore;
